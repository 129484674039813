/* eslint-disable no-irregular-whitespace */
<template>
  <div style="height: 100vh;">
    <v-card flat rounded="0">
      <v-container>
        <div class="d-flex">
          <v-btn @click="$router.push('/finance')" class="my-auto" icon>
            <v-icon>fal fa-arrow-left</v-icon>
          </v-btn>
        </div>
      </v-container>
    </v-card>
    <v-container v-if="loading">
      <v-skeleton-loader
        class="mx-auto"
        type="table-heading, list-item-two-line, image, table-tfoot"
      ></v-skeleton-loader>
    </v-container>
    <v-container v-else fill-height>
      <div class="mx-auto text-center" v-if="noapplicationFound">
        <div>
          <v-col> </v-col>
          <v-icon size="60" color="error">far fa-frown</v-icon>
          <h3>Uh ohh!</h3>
          <div class="mb-5">
            We were unable to find any application with ID<br />
            {{ this.applicationId }}.
          </div>
          <v-btn
            @click="$router.push('/finance/check-application')"
            outlined
            rounded
            text
          >
            Check Another Application
          </v-btn>
        </div>
      </div>
      <div v-else-if="rejected">
        <div class="text-center">
          <v-icon size="60" color="error">far fa-frown</v-icon>
          <h1>Uh ohh!</h1>
          <div>
            Unfortunately, Your application did not meet the minimum required
            criteria for loan approval. Please read more about it
            <a href="">here</a>. If you believe there has been a mistake, reach
            out to us at
            <span class="font-weight-bold">support@ontrack.in</span>
          </div>
          <div class="my-10">
            <v-btn color="primary" large @click="goBack">
              Try Again
            </v-btn>
          </div>
        </div>
        <v-card>
          <v-card-title>
            What Should you do now ?
          </v-card-title>

          <v-card-subtitle>
            Most people have their own reasons for why they want to buy a
            motorcycle. You may also have a plenty of reasons to buy an amazing
            two-wheeler. With numerous bike loans available, it is easy for you
            to buy a motorcycle of your choice. Nevertheless, you would want to
            know if you are eligible for it.
          </v-card-subtitle>

          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header style="border:0;">
                Read more about eligibility criteria
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                What are the factors that determine an individual’s bike loan
                eligibility? Most banks are extremely careful, and they approve
                the bike loan only after ensuring the financial stability of the
                borrower. Moreover, evaluating an individual’s two wheeler loan
                eligibility depends on certain aspects the bank considers.
                However, some common ones that most lenders look for bike
                financing are:
                <ul>
                  <li>
                    <strong>Age:</strong> You should be 21 years or above. If
                    you are between 18 and 21 years, you need a co-applicant.
                    Your maximum age at the end of the bike loan term should be
                    65 years or the age at the time of retirement, whichever
                    comes earlier.
                  </li>
                  <li>
                    <strong>Income: </strong>You should be employed and draw a
                    minimum annual salary, which differs from one bank to
                    another bank. For those who are self-employed, the minimum
                    annual income is again different. If you have a higher
                    salary or income, you will be eligible for a higher amount
                    of bike loan. However, the higher income will not guarantee
                    a bike loan for you.
                  </li>
                  <li>
                    <strong>Minimum work experience: </strong>Even though
                    minimum work experience differs from one bank to another
                    bank, you should have at least six months’ experience
                    working in your present employment.In the case of
                    entrepreneurs or self-employed individuals, they should have
                    been running the business for a minimum period of two years
                    to qualify for a bike loan eligibility.
                  </li>
                  <li>
                    <strong>Repayment capability: </strong>Your repayment
                    capacity is one of the important aspects a bank considers
                    before sanctioning a bike loan. In the process, it will
                    assess your repayment history, as well as the other loans
                    you have now, to ensure whether you have the wherewithal to
                    pay. For that, your bank reviews your income and figure out
                    your debt service coverage ratio. If it is less than 1, you
                    have a fewer chance to secure a two wheeler loan eligibility
                    or you will get less loan amount.
                  </li>
                  <li>
                    <strong>Credit score: </strong>You need to have a good
                    credit history to be considered for a motorcycle loan. This
                    score is a three-digit number, and it shows your
                    creditworthiness. You can avail a bike loan at a lower rate
                    of interest if you have a credit score of 750 and above.
                  </li>
                  <li>
                    <strong>Your residence: </strong>The place where you live is
                    also considered for bike financing. Living in a city gives
                    you a better chance of having your loan approved than living
                    in a countryside.
                  </li>
                  <li>
                    <strong>Your employment: </strong>A bank may also consider
                    the company you work for. This will help it know whether you
                    have a stable job with a regular income.
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
        <v-container>
          <div class="pa-2">
            <p class="font-italic caption text--disabled text-justify">
              <span class="text--disabled"
                ><strong>Disclaimer</strong><br
              /></span>
              The contents of this article / infographic / picture / video are
              meant solely for information purposes. The contents are generic in
              nature and for informational purposes only. It is not a substitute
              for specific advice in your own circumstances. The information is
              subject to updation, completion, revision, verification and
              amendment and the same may change materially. Ontrack Technologies
              shall not be responsible for any direct/indirect loss or liability
              incurred by the reader for taking any financial decisions based on
              the contents and information mentioned. Please consult your
              financial advisor before making any financial decision.
            </p>
          </div>
        </v-container>
      </div>
      <div v-else-if="approved">
        <!--request approved container-->
        <v-container>
          <div class="text-center my-5">
            <v-icon size="60" color="success">fal fa-check-circle</v-icon>
            <h1>Request Approved</h1>
            <div>
              Congratulations
              <strong>{{ loanInformation.loanInformation.firstName }}</strong>
            </div>
            <div class="text--disabled">
              Your loan application is accepted and soft-approved.
            </div>
          </div>
        </v-container>
        <!--Loan details container-->
        <v-row class="my-5">
          <v-col cols="12" md="6">
            <v-card rounded="xl">
              <v-container>
                <v-row class="my-2">
                  <v-col class="my-auto" cols="6"
                    ><strong>Application ID</strong></v-col
                  >
                  <v-col cols="6" class="text-right">
                    <v-card class="text-center" @click="copy(applicationId)">
                      <v-container>
                        <div>
                          <strong>{{ applicationId }}</strong>
                        </div>
                        <div class="text--disabled text-caption">
                          Tap to copy
                        </div>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
                <div class="text--disabled">
                  <strong> Loan Details</strong>
                  <v-divider class="my-2"></v-divider>
                </div>
                <v-row>
                  <v-col><strong>Vehicle Name</strong></v-col>
                  <v-col class="text-right">{{
                    emiDetails.product[0].offers[0].productName.toLowerCase()
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <strong>Amount Eligible</strong>
                  </v-col>
                  <v-col class="text-right"
                    >{{
                      emiDetails.ScorecardEligibleAmount ||
                        "Not Alloted" | currency
                    }}.00</v-col
                  >
                </v-row>
                <v-row>
                  <v-col><strong>Downpayment</strong></v-col>
                  <v-col class="text-right"
                    >{{
                      loanInformation.loanInformation.marginmoney | currency
                    }}.00</v-col
                  >
                </v-row>
                <v-row>
                  <v-col>
                    <strong>
                      EMI
                    </strong>
                  </v-col>
                  <v-col class="text-right">
                    {{ emiDetails.product[0].EMIAmount | currency }} /month
                  </v-col>
                </v-row>
                <v-row>
                  <v-col><strong>Tenure</strong></v-col>
                  <v-col class="text-right"
                    >{{ emiDetails.product[0].netTenor }} Months</v-col
                  >
                </v-row>
                <v-card class="my-4" color="green lighten-5" flat>
                  <v-container>
                    <div class="d-flex justify-center">
                      <v-icon small> far fa-lightbulb-on</v-icon>
                      <div>
                        Ensure
                        <span class="primary--text">KYC Document</span> is valid
                        to avoid rejection.
                      </div>
                    </div>
                  </v-container>
                </v-card>
                <v-row>
                  <v-col cols="12">
                    <!-- <v-btn block class="banner-know-more-text" color="#ffffff" large
              >Download Acknowledgement Letter -->

                    <div v-if="emiDetails.ScorecardEligibleAmount">
                      <acknowledge
                        :preferredDealer="preferredDealer"
                        :loanInformation="emiDetails"
                        :customerInformation="loanInformation.loanInformation"
                        :assetInformation="loanInformation.assetInformation"
                      ></acknowledge>
                    </div>
                    <v-card v-else class="pa-2 my-4" outlined>
                      <div>
                        There might be changes required to further boost your
                        financing.
                      </div>
                      <h4>
                        <strong>Recommended Changes :</strong>
                      </h4>
                      <v-alert
                        class="my-5"
                        color="deep-orange"
                        icon="fal fa-chart-line"
                        outlined
                        text
                      >
                        <div>
                          <strong>Increase Downpayment</strong>
                        </div>
                        <div>
                          This can be done during your dealership visit.
                        </div>
                      </v-alert>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>

          <!-- Dealership container -->
          <v-col cols="12" md="6">
            <v-card rounded="xl">
              <div class="banner-ot-recommended white--text">
                <div class="d-flex justify-center">
                  <v-icon dark small>fa-solid fa-star</v-icon>
                  <div class="ml-2 text-caption">
                    ONTRACK RECOMMENDED DEALERSHIP
                  </div>
                </div>
              </div>
              <div>
                <GmapMap
                  :center="preferredDealerMapData"
                  :options="{
                    fullscreenControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    disableDefaultUi: true,
                    zoomControl: false,
                    gestureHandling: 'cooperative',
                  }"
                  :zoom="15"
                  map-type-id="terrain"
                  style="width: 100%; height: 30vh;scroll-behavior: smooth;border-radius:20px"
                >
                  <GmapMarker
                    :clickable="true"
                    :draggable="false"
                    :icon="{
                      url:
                        'https://on-track-jarvis.s3.ap-south-1.amazonaws.com/1fe4857c-ea4d-4820-8dd1-40a780265a0e.svg',
                    }"
                    :position="preferredDealerMapData"
                  />
                </GmapMap>
                <v-container>
                  <v-row>
                    <v-col class="my-auto" cols="9">
                      <div>
                        <strong> {{ preferredDealer.dealer.name }}</strong>
                      </div>
                    </v-col>
                    <v-col cols="3" class="text-right my-auto">
                      <v-btn
                        icon
                        @click="dial(preferredDealer.dealer.dContact)"
                      >
                        <v-icon small>
                          fa-light fa-phone
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="d-flex my-auto">
                        <v-icon color="black" small class="ma-0 pa-0 mr-2"
                          >fal fa-map-marker-alt</v-icon
                        >
                        <div>
                          {{ preferredDealer.dealer.address }}
                        </div>
                      </div>
                    </v-col>
                    <v-col class="text-right" cols="2">
                      <v-btn icon>
                        <v-icon @click="copy(preferredDealer.dealer.address)"
                          >fal fa-copy</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-card class="text-center my-4">
                    <v-container>
                      <div
                        class="d-flex justify-center my-auto"
                        @click="lookupLocation"
                      >
                        <v-icon small>fal fa-directions</v-icon>
                        <div class="ml-2">
                          Get Directions
                        </div>
                      </div>
                    </v-container>
                  </v-card>
                  <div class="d-flex my-auto">
                    <v-icon small>fal fa-clock</v-icon>
                    <div class="ml-4">
                      Open Until 8:00 PM
                    </div>
                  </div>
                  <div class="d-flex my-auto">
                    <v-icon small>fal fa-star</v-icon>
                    <div class="ml-4">Rated 4.5</div>
                  </div>
                </v-container>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text block large v-bind="attrs" v-on="on" class="ma-0">
              View All Dealers
            </v-btn>
          </template>
          <div cols="12 smooth scroll" style="min-heightt:100vh">
            <GmapMap
              :center="center"
              :options="{
                fullscreenControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                disableDefaultUi: true,
                zoomControl: false,
                gestureHandling: 'cooperative',
              }"
              :zoom="15"
              map-type-id="terrain"
              style="width: 100%; height: 100vh;scroll-behavior: smooth;"
            >
              <GmapMarker
                v-for="(m, index) in dealerMapData"
                :key="index"
                :clickable="true"
                :draggable="false"
                :icon="{
                  url:
                    'https://on-track-jarvis.s3.ap-south-1.amazonaws.com/1fe4857c-ea4d-4820-8dd1-40a780265a0e.svg',
                }"
                :position="m.position"
                @click="
                  setCenter(m.dealer.latitude, m.dealer.longitude, m.dealer)
                "
              />
            </GmapMap>
            <div class="cards-header px-2 pa-0" lg="4" md="6" xs="12">
              <div v-if="selectedDealer" class="pa-0" lg="4" md="6" xs="12">
                <v-btn
                  class="mx-2"
                  color="primary"
                  fab
                  rounded
                  tile
                  x-small
                  @click="dialog = false"
                >
                  <v-icon dark>
                    fal fa-arrow-left
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div class="cards-container pa-2" lg="4" md="6" xs="12">
              <v-card v-if="selectedDealer" class="pa-4" lg="4" md="6" xs="12">
                <p class="caption text--disabled ma-0 pa-0">
                  <v-icon x-small>mdi-star</v-icon>
                  Ontrack Recommended
                </p>
                <p class="text-overline ma-0 pa-0">
                  {{ selectedDealer.name }}
                </p>
                <p class="caption text--disabled ma-0 pa-0">
                  {{ selectedDealer.address }}
                </p>
                <div class="my-2">
                  <v-btn
                    color="primary"
                    tile
                    @click="dial(selectedDealer.dContact)"
                  >
                    <v-icon small left>
                      fal fa-phone
                    </v-icon>
                    Call
                  </v-btn>
                  <v-btn
                    :href="selectedDealer.mapLink"
                    class="ml-2"
                    color="primary"
                    outlined
                    target="_blank"
                  >
                    <v-icon small left>
                      fal fa-map
                    </v-icon>
                    View in Map
                  </v-btn>
                </div>
              </v-card>
            </div>
          </div>
        </v-dialog>
      </div>
      <div v-else-if="showExceptionCase">
        This is taking longer than usual, please contact our support team for
        further process.
      </div>
      <div class="my-10 text-center pa-4" v-else-if="scorecardPending">
        <div class="my-10">
          <div>
            <div class="my-4 text-center">
              <v-icon large color="primary">fal fa-hourglass-half</v-icon>
              <p class="my-4" style="font-size: x-large">Request Pending!</p>
            </div>
            <span class="caption">
              It looks like your loan was accepted by the financer but needs
              more verification, thank you for your patience.<br />
              Please check back later and note your application ID/Reference :
              <br />
              <v-btn
                text
                color="primary"
                @click="copy(applicationId)"
                outlined
                >{{ applicationId }}</v-btn
              >
              <br />
              Alternatively you can contact our dealer for more info.
            </span>
          </div>
        </div>
      </div>
      <div class="py-5">
        <v-card v-if="selectedDealer" class="pa-4" lg="4" md="6" xs="12">
          <p class="caption text--disabled ma-0 pa-0">
            <v-icon x-small>mdi-star</v-icon>
            Ontrack Recommended
          </p>
          <p class="text-overline ma-0 pa-0">
            {{ selectedDealer.name }}
          </p>
          <p class="caption text--disabled ma-0 pa-0">
            {{ selectedDealer.address }}
          </p>
          <div class="my-2">
            <v-btn color="primary" @click="dial(selectedDealer.dContact)">
              <v-icon>
                fal fa-phone
              </v-icon>
            </v-btn>
            <v-btn
              :href="selectedDealer.mapLink"
              class="ml-2"
              color="primary"
              outlined
              target="_blank"
            >
              <v-icon small left>
                fal fa-map
              </v-icon>
              View in Map
            </v-btn>
          </div>
        </v-card>
      </div>
      <div class="text-center mx-auto">
        <v-btn text color="primary" @click="$router.push('/')"
          >Back to Home</v-btn
        >
      </div>
      <v-snackbar dark outlined timeout="1000" v-model="copied">
        Copied Successfully.
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
// import axios from "axios";
import acknowledge from "../views/pdf/acknowledge.vue";
import * as _ from "lodash";
export default {
  name: "Result",
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    hover: false,
    applicationId: null,
    loanInformation: null,
    isLoading: null,
    preferredDealer: null,
    searchArea: "",
    loanDeatils: "",
    pending: null,
    rejected: null,
    approved: null,
    showExceptionCase: null,
    runCheck: null,
    emiDetails: null,
    availableDealersGroup: null,
    reveal: false,
    scorecardPending: false,
    noapplicationFound: false,
    show: false,
    dealerMapData: [],
    currentLocation: "",
    center: "",

    selectedDealer: null,
    copied: false,
    preferredDealerMapData: null,
    loading: false,
  }),
  components: {
    acknowledge,
  },
  async created() {
    this.applicationId = this.$route.params.id;
    this.checkIfApproved();
    this.getLoanInformation();
  },
  methods: {
    dial: function(number) {
      window.location = "tel:" + number;
    },
    sendmail: function(address) {
      window.location = "mailto:" + address;
    },
    showDealerMapFn() {
      this.selectedDealer = this.savedLoanData.preferredDealer.dealer;
      this.showDealerMap = true;
    },
    selectDealer(dealer) {
      this.selectedDealer = dealer;
    },
    setCenter(lat, long, dealer) {
      console.log(lat, long);
      this.center = { lat: parseFloat(lat), lng: parseFloat(long) };
      if (dealer) {
        this.selectDealer(dealer);
      }
    },
    async checkIfApproved() {
      this.loading = true;
      try {
        var response = await this.$store.dispatch("checkLoanStatus", {
          applicationId: this.applicationId,
        });
      } finally {
        if (response.data.status.responseCode == 1) {
          this.noapplicationFound = true;
          this.isLoading = false;
        } else {
          if (
            ["Scorecard Approved"].includes(
              response.data.loanDetails[0].subStatus
            )
          ) {
            this.emiDetails = response.data.loanDetails[0];
            this.approved = true;
          } else if (
            ["Scorecard Reject", "Posidex Reject"].includes(
              response.data.loanDetails[0].subStatus
            )
          ) {
            this.rejected = true;
          } else if (
            ["Scorecard Pending"].includes(
              response.data.loanDetails[0].subStatus
            )
          ) {
            this.scorecardPending = true;
          } else {
            this.runCheck = window.setInterval(() => {
              this.checkStatus();
            }, 5000);
          }
        }
        this.loading = false;
      }
    },
    async copy(id) {
      try {
        await navigator.clipboard.writeText(id);
        this.copied = true;
      } catch ($e) {
        alert("Cannot copy, please copy manually!");
      }
    },
    async checkStatus() {
      this.counter++;
      var response = this.$store.dispatch("checkLoanStatus", {
        applicationId: this.applicationId,
      });
      if (response.data.loanDetails[0].subStatus == "Scorecard Approved") {
        window.clearInterval(this.runCheck);
        this.approved = true;
        this.emiDetails = response.data.loanDetails[0];
      } else if (
        ["Scorecard Reject", "Posidex Reject"].includes(
          response.data.loanDetails[0].subStatus
        )
      ) {
        this.rejected = true;
        window.clearInterval(this.runCheck);
      } else if (this.counter > 120) {
        this.showExceptionCase = true;
        window.clearInterval(this.runCheck);
      }
    },
    lookupLocation() {
      window.location.href =
        "https://www.google.com/maps/dir/?api=1&origin=" +
        this.preferredDealerMapData.lat +
        ", " +
        this.preferredDealerMapData.lng;
    },
    async getLoanInformation() {
      this.isLoading = true;
      try {
        // this.loanDeatils = this.$store.dispatch("checkLoanStatus", {
        //   applicationId: this.applicationId
        // });
        // const { data } = await axios.get(
        //   `http://localhost:3000/api/finance/check/dealers/${this.applicationId}`
        // );
        const { data } = await this.$api.HTTP2.get(
          `finance/check/dealers/${this.applicationId}`
        );
        this.loanInformation = data;
        this.preferredDealer = this.loanInformation.preferredDealer;
        this.availableDealersGroup = _.groupBy(
          this.loanInformation.availableDealers,
          "name"
        );
        this.setCenter(
          this.loanInformation.preferredDealer.dealer.latitude,
          this.loanInformation.preferredDealer.dealer.longitude
        );
        this.preferredDealerMapData = {
          lat: parseFloat(this.loanInformation.preferredDealer.dealer.latitude),
          lng: parseFloat(
            this.loanInformation.preferredDealer.dealer.longitude
          ),
        };
        this.selectDealer(this.preferredDealer.dealer);
        this.loanInformation.availableDealers.forEach((x) => {
          this.dealerMapData.push({
            position: {
              lat: parseFloat(x.latitude),
              lng: parseFloat(x.longitude),
            },
            dealer: x,
          });
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    // dealersList () {
    //   return this.loanInformation.availableDealers;
    // },
    dealersList() {
      return this.availableDealersGroup;
    },
  },
};
</script>
<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.cards-container {
  position: fixed;
  margin: 5% auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  bottom: 30px;
  z-index: 9999;
  width: 50%;
}
.cards-header {
  position: fixed;
  margin: 5% auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  top: 0px;
  z-index: 9999;
  width: 50%;
}
@media only screen and (max-width: 600px) {
  .cards-container {
    position: fixed;
    margin: 5% auto; /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    bottom: 30px;
    z-index: 9999;
    width: 100% !important;
  }
  .cards-header {
    position: fixed;
    margin: 5% auto; /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    top: 0px;
    z-index: 9999;
    width: 100% !important;
  }
}
.gmap {
  width: 100%;
  height: 200px;
  background-color: #ffffff;
}
.bold {
  font-weight: bold;
}
.smooth {
  scroll-behavior: smooth;
}
html {
  scroll-behavior: smooth;
}
.text-special {
  color: #76ae46;
}
.text-statement {
  font-weight: bold;
  color: #333333;
}
.color-special {
}
.loan-details {
  background-color: #f6f6f6;
  border-radius: 20px;
  margin: 20px 0;
}
.label {
  color: #515151;
  font-size: 14px;
  font-weight: bolder;
  text-transform: uppercase;
}
.value {
  color: #5f3ad7;
}
.appid {
  font-size: 18px;
  border: 1px solid #76ae46;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
}
.reference-label {
  padding: 5px;
}
.banner-loan-details {
  background-color: #333333;
  padding: 0;
  margin-bottom: 15px;
}
.label-special-loan-details {
  color: #ffffff;
  margin: auto 0;
}
.banner-tip {
  background-color: #d4d8f7;
  padding: 5px;
  margin-bottom: 10px;
}
.text-statement-small {
  color: #333333;
  margin: auto 0;
  font-size: 12px;
  font-weight: bold;
}
.kyc-word {
  color: #4f43d8;
}
.banner-know-more {
  background-color: #4f43d8;
  padding: 20px 10px;
  border-radius: 0px 0px 20px 20px;
}
.banner-know-more-text {
  font-size: 11px;
  margin-right: 15px;
}
.container-dealership {
  background-color: #f6f6f6;
  border-radius: 20px;
}
.banner-ot-recommended {
  background: linear-gradient(
    90deg,
    rgba(79, 67, 216, 1) 0%,
    rgba(142, 35, 215, 1) 100%
  );
  padding: 5px 10px;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
}
.banner-ot-recommended-text {
  font-size: 10px;
  color: #ffffff;
  margin: auto 0;
}
.container-dealer-name {
  margin: 0 auto;
}
.text-dealername {
  font-size: 18px;
}
.container-phone-btn {
  background-color: #d4d9f9;
  border-radius: 10px;
}
.row-gmap {
}
.container-dealer-address {
  margin-top: 10px;
}
.dealer-address {
  font-size: 14px;
  letter-spacing: 1px;
  text-align: left;
  align-content: center;
}
.get-directions {
  border: 1px solid #333333;
  border-radius: 10px;
  margin: auto 5px;
  padding-top: 5px;
}
.all-dealers-container {
  padding: 20px;
  border-radius: 0px 0px 20px 20px !important;
  background-color: #4e44d8;
}
</style>
